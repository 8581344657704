@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");

:root {
  --bg-color: #fff;
  --text-color: #000;
  --card-color: #eeeeee;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.App {
  background-color: var(--bg-color);
  min-height: 100vh;
  color: var(--text-color);
  transition: 800ms;
}

.main {
  display: flex;
  padding: 10px 10px;
}

header {
  min-width: 300px;
  padding: 50px;
  box-sizing: border-box;
  position: relative;
}

header h1 {
  font-size: medium;
  font-weight: normal;
  color: var(--text-color);
}

header p {
  font-size: smaller;
  color: gray;
}

header .darkicon {
  margin-top: auto;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 2.2em;
}

header .darkicon > * {
  color: black;
}

.header-content {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 200px;
  height: 85vh;
}

.filter-btn {
  position: fixed;
  z-index: 104;
  font-size: 3em;
  color: var(--text-color);
  right: 25px;
  bottom: 20px;
  cursor: pointer;
  transition: 500ms;
}

.filter-menu-wrapper {
  position: fixed;
  z-index: 103;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 500ms;
}

.filter-menu ul li {
  color: white;
  list-style-type: none;
  font-size: 2em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
  /* transition: all 600ms ease-in-out; */
}

/* Style your items */
.my-masonry-grid_column > div img {
  /* change div to reference your elements you put in <Masonry> */
  width: 100%;
  margin-bottom: 5px;
}

.lightbox-title {
  color: white;
  font-size: smaller;
  position: relative;
  top: -10px;
  text-align: center;
  pointer-events: none;
}

.lightbox-btn-wrapper {
  position: fixed;
  z-index: 401;
  right: 20px;
  top: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.lightbox-btn-wrapper > div {
  font-size: 2em;
  transition: 300ms;
  color: white;
}

.lightbox-btn-wrapper > div:hover {
  color: gray;
  transition: 300ms;
}

.lightbox-btn-wrapper > div.next-btn:active {
  transform: scale(1.3) translateX(5px);
  transition: 300ms;
}

.lightbox-btn-wrapper > div.prev-btn:active {
  transform: scale(1.3) translateX(-5px);
  transition: 300ms;
}

.video-gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2em;
  row-gap: 2em;
}

.video-card {
  /* width: 300px; */
  background-color: var(--card-color);
  border-radius: 0.4em;
  overflow: hidden;
  transition: 300ms;
}

.video-card img {
  width: 100%;
}

.video-card .video-card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
}

.video-card .video-card-info .play-icon {
  font-size: 1.5em;
  cursor: pointer;
}

.video-card .video-card-info .duration {
  font-size: 0.8em;
}

.player-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 106;
}

.player-close-btn {
  display: inline-block;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 107;
  font-size: 2.5em;
  cursor: pointer;
  color: white;
}

.react-player {
  width: 100%;
  padding: 20px;
}

.category-head {
  text-transform: capitalize;
  background-color: var(--bg-color);
  color: var(--text-color);
  display: inline-block;
  padding: 12px;
  margin-bottom: 10px;
  position: fixed;
  bottom: 29px;
  right: 80px;
  border-radius: 3em;
}

.loader {
  border: 8px solid #535353; /* Light grey */
  border-top: 8px solid #ffffff; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1100px) {
  .main {
    flex-direction: column;
    padding: 0px 15px;
  }

  header {
    min-width: 100%;
    padding: 25px 0px;
    box-sizing: border-box;
    position: relative;
  }

  header p {
    margin-left: 1em;
  }

  .header-content {
    position: static;
    align-items: center;
    width: auto;
    flex-direction: row;
    height: auto;
  }

  header .darkicon {
    margin-top: unset;
    margin-left: auto;
  }

  .my-masonry-grid {
    margin-left: -10px;
  }
}

@media (max-width: 500px) {
  header p {
    display: none;
  }

  .lightbox-title {
    top: -50px;
  }

  .lightbox-btn-wrapper {
    left: 0px;
    bottom: 0px;
    top: auto;
    min-height: 50px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
  }

  .lightbox-btn-wrapper > div {
    font-size: 2em;
    transition: 300ms;
  }

  .lightbox-btn-wrapper > div:hover {
    color: unset;
  }

  .video-gallery {
    grid-template-columns: 1fr;
    column-gap: 2em;
    row-gap: 2em;
  }

  .react-player {
    padding: 10px;
  }
}
